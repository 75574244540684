import { GridOptions } from 'ag-grid-community';
import SelectorFloatingFilter from 'components/ReactGrid/filters/SelectorFloatingFilter';
import { CheckedCell } from 'components/ReactGrid/renderers';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import {
  booleanSelectorFilterParams,
  numberFilterParams,
} from 'helpers/filterParams';

export const placeGridOptions = {
  headerHeight: 40,
  components: {
    CustomCell,
    CheckedCell,
    SelectorFloatingFilter,
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressSideButton: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  defaultColDef: {
    initialHide: false,
    enableRowGroup: false,
    editable: false,
    cellRenderer: 'CustomCell',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: { newRowsAction: 'keep', debounceMs: 1500 },
  },
  suppressScrollOnNewData: true,
  columnDefs: [
    {
      headerName: 'Division',
      field: 'division.name',
    },
    {
      headerName: 'Place ID',
      field: 'id',
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Place Name',
      field: 'placeName',
    },
    {
      headerName: 'Site Id',
      field: 'siteId',
    },
    {
      headerName: 'Site Note',
      field: 'siteNote',
    },
    {
      headerName: 'Sublocation 1',
      field: 'subLocation1',
    },
    {
      headerName: 'Sublocation 2',
      field: 'subLocation2',
    },
    {
      headerName: 'Stack Run',
      field: 'stackRun',
      width: 100,
      cellRenderer: 'CheckedCell',
      floatingFilterComponent: 'SelectorFloatingFilter',
      filterParams: booleanSelectorFilterParams,
    },
    {
      headerName: 'Address',
      field: 'address.address1',
    },
    {
      headerName: 'Suburb',
      field: 'address.addressCodeLocality.name',
    },
    {
      headerName: 'Place',
      field: 'address.addressCodePlace.name',
    },
    {
      headerName: 'Place Type',
      field: 'address.addressCodePlaceType.name',
    },
    {
      headerName: 'Postcode',
      field: 'address.postCode',
    },
    {
      headerName: 'Region',
      field: 'address.addressCodeRegion.name',
    },
    {
      headerName: 'Region Code',
      field: 'address.addressCodeRegion.code',
    },
    {
      headerName: 'Country',
      field: 'address.addressCodeCountry.name',
    },
    {
      headerName: 'Country Code',
      field: 'address.addressCodeCountry.code',
    },
    {
      headerName: 'First Name',
      field: 'organizationLocationContactMappings.some.contact.firstName',
      valueGetter: params => {
        const contacts = params?.data?.organizationLocationContactMappings;
        if (contacts?.length > 0) {
          const defaultContact =
            contacts?.find(item => item.isDefault === true) || contacts[0];
          return defaultContact?.contact?.firstName;
        }
        return null;
      },
    },
    {
      headerName: 'Last Name',
      field: 'organizationLocationContactMappings.some.contact.lastName',
      valueGetter: params => {
        const contacts = params?.data?.organizationLocationContactMappings;
        if (contacts?.length > 0) {
          const defaultContact =
            contacts?.find(item => item.isDefault === true) || contacts[0];
          return defaultContact?.contact?.lastName;
        }
        return null;
      },
    },
    {
      headerName: 'Email',
      field: 'organizationLocationContactMappings.some.contact.email',
      valueGetter: params => {
        const contacts = params?.data?.organizationLocationContactMappings;
        if (contacts?.length > 0) {
          const defaultContact =
            contacts?.find(item => item.isDefault === true) || contacts[0];
          return defaultContact?.contact?.email;
        }
        return null;
      },
    },
    {
      headerName: 'Phone',
      field: 'organizationLocationContactMappings.some.contact.phone',
      valueGetter: params => {
        const contacts = params?.data?.organizationLocationContactMappings;
        if (contacts?.length > 0) {
          const defaultContact =
            contacts?.find(item => item.isDefault === true) || contacts[0];
          return defaultContact?.contact?.phone;
        }
        return null;
      },
    },
    {
      headerName: 'Mobile',
      field: 'organizationLocationContactMappings.some.contact.mobile',
      valueGetter: params => {
        const contacts = params?.data?.organizationLocationContactMappings;
        if (contacts?.length > 0) {
          const defaultContact =
            contacts?.find(item => item.isDefault === true) || contacts[0];
          return defaultContact?.contact?.mobile;
        }
        return null;
      },
    },
  ],
} as GridOptions;
