import { containerSizes } from './booking';

export const FreightGoodsTypeOptions = [
  { label: 'AMBIENT', value: '1', type: 'number' },
  { label: 'CHILLED', value: '2', type: 'number' },
  { label: 'FROZEN', value: '3', type: 'number' },
  { label: 'OTHER ', value: '4', type: 'number' },
];

export const GenericCodeBookingServiceType = [
  { label: 'Economy', value: 7 },
  { label: 'Standard', value: 8 },
  { label: 'Express', value: 9 },
  { label: 'Urgent', value: 10 },
];

export const UnitOfMeasureTypeOptions = [
  { label: 'Job', value: 1 },
  { label: 'Each', value: 2 },
  { label: 'Weight', value: 3 },
  { label: 'Cubic', value: 4 },
  { label: 'Kilometer', value: 5 },
  { label: 'Hour', value: 6 },
];

export const filterConditions = {
  greaterThan: 'gte',
  lessThan: 'lte',
  equals: 'eq',
  notEqual: 'neq',
  notContains: 'ncontains',
  notBlank: 'nblk',
  blank: 'blk',
};

export const StatusColumnMapping = {
  status: 'status',
  invoicingStatus: 'invoiceStatus',
  invoiceStatus: 'status',
};

const BooleanFilterOptions = [
  { label: 'YES', value: 'true', type: 'boolean', width: '50px' },
  { label: 'NO', value: 'false', type: 'boolean', width: '50px' },
];

export const SelectorFloatingFilterOptions = {
  status: [
    { label: 'ACC', value: 'ACC', bgColor: '#1E90FF', width: '50px' },
    { label: 'ALL', value: 'ALL', bgColor: '#7fff00', width: '50px' },
    { label: 'CANC', value: 'CANC', bgColor: '#FF0C0C', width: '50px' },
    { label: 'DEL', value: 'DEL', bgColor: '#ffffff', width: '50px' },
    { label: 'HOLD', value: 'HOLD', bgColor: '#9CA1AC', width: '50px' },
    { label: 'NEW', value: 'NEW', bgColor: '#eee8aa', width: '50px' },
    { label: 'OFL', value: 'OFL', bgColor: '#87ceeb', width: '50px' },
    { label: 'PAK', value: 'PAK', bgColor: '#FF7A00', width: '50px' },
    { label: 'PLN', value: 'PLN', bgColor: '#FF3399', width: '50px' },
    { label: 'POD', value: 'POD', bgColor: '#fffce0', width: '50px' },
    { label: 'PUP', value: 'PUP', bgColor: '#0f0ffa99', width: '50px' },
    { label: 'RDY', value: 'RDY', bgColor: '#C230FF', width: '50px' },
    { label: 'RFP', value: 'RFP', bgColor: '#FF3C3C', width: '50px' },
    { label: 'UPK', value: 'UPK', bgColor: '#FF7A00', width: '50px' },
    { label: 'UPL', value: 'UPL', bgColor: '#2b95ff', width: '50px' },
    { label: 'WEB', value: 'WEB', bgColor: '#464646a4', width: '50px' },
  ],
  invoicingStatus: [
    { label: 'VFED', value: 'VERIFIED', bgColor: '#0096FF', width: '50px' },
    { label: 'INVC', value: 'INVOICED', bgColor: '#FF7A00', width: '50px' },
  ],
  invoiceStatus: [
    { label: 'INVC', value: 1, bgColor: '#FF7A00', width: '50px' },
    { label: 'CANC', value: 2, bgColor: '#D30228', width: '50px' },
  ],
  shift: [
    { label: 'DAY', value: 'DAY', width: '50px' },
    { label: 'NIGHT', value: 'NIGHT', width: '50px' },
  ],
  bookingType: [
    { label: 'Container', value: 'Container', width: '80px' },
    { label: 'Freight', value: 'Freight', width: '80px' },
  ],
  isChargeable: BooleanFilterOptions,
  rateOverride: BooleanFilterOptions,
  noDispatch: BooleanFilterOptions,
  hasDgs: BooleanFilterOptions,
  applyFAF: BooleanFilterOptions,
  stackRun: BooleanFilterOptions,
  isStackRun: BooleanFilterOptions,
  equipmentType: [
    { label: 'VEHICLE', value: '1', type: 'number' },
    { label: 'TRAILER', value: '2', type: 'number' },
  ],
  'unitOfMeasure.name': [
    { label: 'Job', value: 'Job' },
    { label: 'Each', value: 'Each' },
  ],
  goodsType: FreightGoodsTypeOptions,
  bookingFlag: [
    {
      label: 'Attention',
      value: '1',
      textAlign: 'left',
      icon: 'ATTENTIONT',
      type: 'number',
    },
  ],
  containerJobType: [
    { label: 'IMP', value: 'IMP' },
    { label: 'EXP', value: 'EXP' },
    { label: 'MOV', value: 'MOV' },
  ],
  containerFullEmpty: [
    { label: 'FULL', value: 'FULL' },
    { label: 'EMPTY', value: 'EMPTY' },
  ],
  containerType: [
    { label: 'GP', value: 'GP' },
    { label: 'HC', value: 'HC' },
    { label: 'RE', value: 'RE' },
    { label: 'RX', value: 'RX' },
    { label: 'B', value: 'B' },
    { label: 'I', value: 'I' },
    { label: 'T', value: 'T' },
    { label: 'S', value: 'S' },
    { label: 'FR', value: 'FR' },
    { label: 'P', value: 'P' },
    { label: 'V', value: 'V' },
    { label: 'OT', value: 'OT' },
  ],
  containerDoorSide: [
    { label: 'FORWARD', value: 'FORWARD' },
    { label: 'REAR', value: 'REAR' },
    { label: 'EITHER', value: 'EITHER' },
    { label: 'TBA', value: 'TBA' },
  ],
  containerPortCode: [
    { label: 'NZAKL', value: 'NZAKL' },
    { label: 'NZTRG', value: 'NZTRG' },
    { label: 'NZLYT', value: 'NZLYT' },
    { label: 'NZTIU', value: 'NZTIU' },
  ],
  containerSize: containerSizes,
};

export const OrganizationType = {
  Subscriber: 12,
  Customer: 13,
  SubContractor: 14,
  Division: 15,
};

export const dateTypeSelectorOptions = [
  { value: 'bookedDate', label: 'Booked Date' },
  { value: 'lastChanged', label: 'Last Changed' },
  { value: 'pickupRequired', label: 'Pickup Required' },
  { value: 'activityRequired', label: 'Activity Required' },
  { value: 'deliveryRequired', label: 'Delivery Required' },
  { value: 'pickupActual', label: 'Pickup Actual' },
  { value: 'activityActual', label: 'Activity Actual' },
  { value: 'deliveryActual', label: 'Delivery Actual' },
];

export const ServiceProviderStatusTypes = [
  {
    value: 22,
    label: 'Active',
  },
  {
    value: 23,
    label: 'Pending',
  },
  {
    value: 24,
    label: 'Cancelled',
  },
  {
    value: 25,
    label: 'Disabled',
  },
];

export const ServiceProviderDefaultTypes = [
  {
    value: 'True',
    label: 'Default',
  },
  {
    value: 'False',
    label: 'No',
  },
];

const SEARCH_OPTS = {
  STARTS_WITH: 'Starts With',
  CONTAINS: 'Contains',
  ENDS_WITH: 'Ends With',
  EQUALS: 'Equals',
};

const fuzzySearchOptions = [
  { value: 'eq', displayName: SEARCH_OPTS.EQUALS },
  { value: 'startsWith', displayName: SEARCH_OPTS.STARTS_WITH },
  { value: 'endsWith', displayName: SEARCH_OPTS.ENDS_WITH },
  { value: 'contains', displayName: SEARCH_OPTS.CONTAINS },
];

export const searchSelectorOptions = [
  {
    value: 'charge',
    label: 'Charge Rate',
    fuzzySearchOptions: [{ value: 'eq', displayName: 'Equals' }],
  },

  {
    value: 'deliverySiteName',
    label: 'Consignee Name',
    fuzzySearchOptions,
  },
  {
    value: 'deliveryRef',
    label: 'Delivery Reference',
    fuzzySearchOptions,
  },
  {
    value: 'docket',
    label: 'Docket Number',
    fuzzySearchOptions,
  },
  {
    value: 'freightPayerName',
    label: 'Freight Payer',
    fuzzySearchOptions,
  },
  {
    value: 'id',
    label: 'iCOS Reference',
    fuzzySearchOptions: [{ value: 'eq', displayName: 'Equals' }],
  },
  {
    value: 'orderRef',
    label: 'Order Reference',
    fuzzySearchOptions,
  },

  {
    value: 'activitySiteName',
    label: 'Pack/Unpack Name',
    fuzzySearchOptions,
  },
  {
    value: 'pickupRef',
    label: 'Pickup Reference',
    fuzzySearchOptions,
  },
];

export enum PlaceTypesEnum {
  PICKUP = 2,
  ACTIVITY = 3,
  DELIVERY = 4,
  EXTRA = 5,
}
