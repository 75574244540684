import { Checkbox, Tabs, message } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import Dialog from 'components/Dialog';
import { IDialogHeader } from 'components/Dialog/types';
import FormUserActions from 'components/Form/FormUserActions';
import WithThemeProvider from 'components/providers/WithThemeProvider';
import { useFormHooks } from 'context/FormHooksContext';
import { getErrorMessage } from 'helpers/form-errors';
import React, { ReactNode, useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import ContactForm, { contactsRegistedFieldname } from './ContactForm';
import DetailsForm from './DetailsForm';

type Props = {
  place?: any;
  onClose?: () => void;
  onSubmit: (data: any) => void;
} & IDialogHeader;

type FormProps = {
  contactForm: ReactNode;
  detailsForm: ReactNode;
};

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    margin: 0.6rem 0 0.6rem 1.5rem !important;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
`;

const getHeaderTitle = ({ title, onTabSelect }) => (
  <WithThemeProvider
    token={{ colorPrimaryActive: '#EE5919', colorPrimary: '#ee5919' }}
  >
    <div className="flex h-full w-full">
      <h3 className="flex items-center text-[18px] font-semibold whitespace-nowrap">
        {title}
      </h3>
      <StyledTabs
        defaultActiveKey="1"
        className="w-full"
        onTabClick={onTabSelect}
      >
        <TabPane tab="Place Details" key="details" />
        <TabPane tab="Contact" key="contact" />
      </StyledTabs>
    </div>
  </WithThemeProvider>
);

const Form = ({
  onClose,
  onSubmit,
  title,
  contactForm,
  detailsForm,
  place,
}: Props & FormProps) => {
  const [isAbandoning, setIsAbandoning] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState<'details' | 'contact'>(
    'details',
  );
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useFormHooks();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (Object.keys(errors).length) {
      message.error(getErrorMessage(errors));
    }
  }, [errors]);

  // eslint-disable-next-line no-underscore-dangle
  async function _onSave() {
    setIsAbandoning(false);

    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  }

  // eslint-disable-next-line no-underscore-dangle
  function _onCancel() {
    if (isAbandoning || !isDirty) {
      onClose();
      return;
    }

    setIsAbandoning(true);
  }

  function onTabSelect(tabKey: 'details' | 'contact') {
    setTabSelected(tabKey);
  }

  return (
    <form
      ref={formRef}
      className="flex flex-col w-full h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col w-full h-full">
        <Dialog.Header
          onClose={onClose}
          title={getHeaderTitle({ title, onTabSelect })}
        >
          <div className="flex w-full space-x-1">
            <Controller
              control={control}
              name="stackRun"
              render={() => (
                <Checkbox
                  checked={getValues('stackRun')}
                  onChange={e => {
                    setValue('stackRun', e.target.checked);
                  }}
                />
              )}
            />
            <span>Stack Run</span>
          </div>
        </Dialog.Header>
        {tabSelected === 'contact' ? contactForm : detailsForm}
      </div>
      <FormUserActions
        onCancel={_onCancel}
        onSave={_onSave}
        isAbandoning={isAbandoning}
        isLoading={place?.isSaving}
      />
    </form>
  );
};

export default function Index({ place = null, ...rest }: Props) {
  const { register } = useFormHooks();
  useEffect(() => {
    register(contactsRegistedFieldname);
  }, []);

  return (
    <Form
      {...rest}
      place={place}
      contactForm={<ContactForm key="contact-form" />}
      detailsForm={<DetailsForm key="details" />}
    />
  );
}
