import DateFormats from 'components/config/date';
import {
  StatusColumnMapping,
  filterConditions,
} from 'components/config/types/common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const dateTimeFormat = DateFormats.asPayloadDateTime;

function plusOneDayUTC(date) {
  return dayjs(date).add(1, 'days').utc().format(dateTimeFormat);
}

function generalCustomFilterGenerator(newKeys, data, operator?) {
  let result;
  function recursionGeneral(key, count, data) {
    if (count === 1) {
      if (data?.nextDate) {
        return {
          [key[key.length - count]]: { gte: data.value, lte: data.nextDate },
        };
      }
      return { [key[key.length - count]]: { [data.type]: data.value } };
    }
    return {
      [key[key.length - count]]: recursionGeneral(key, count - 1, data),
    };
  }

  if (operator) {
    result = {
      [operator]: [
        recursionGeneral(newKeys, newKeys.length, data[0]),
        recursionGeneral(newKeys, newKeys.length, data[1]),
      ],
    };
  } else {
    result = recursionGeneral(newKeys, newKeys.length, data);
  }
  return result;
}

function generateOperationDateFilterParams(data, selectedDate1, selectedDate2) {
  if (data[0].type === filterConditions.equals && selectedDate1) {
    // if condition is "equals", then setup the nextDate
    data[0].nextDate = plusOneDayUTC(selectedDate1);
  }
  if (data[1].type === filterConditions.equals && selectedDate2) {
    // if condition is "equals", then setup the nextDate
    data[1].nextDate = plusOneDayUTC(selectedDate2);
  }
  if (data[0].type === filterConditions.lessThan && selectedDate1) {
    // if condition is "lte", then setup the nextDate
    data[0].value = plusOneDayUTC(selectedDate1);
  }
  if (data[1].type === filterConditions.lessThan && selectedDate2) {
    // if condition is "lte", then setup the nextDate
    data[1].value = plusOneDayUTC(selectedDate2);
  }
  if (data[0].type === filterConditions.notBlank) {
    data[0].value = null;
    data[0].type = filterConditions.notEqual;
  }
  if (data[1].type === filterConditions.notBlank) {
    data[1].value = null;
    data[1].type = filterConditions.notEqual;
  }
  if (data[0].type === filterConditions.blank) {
    data[0].value = null;
    data[0].type = filterConditions.equals;
  }
  if (data[1].type === filterConditions.blank) {
    data[1].value = null;
    data[1].type = filterConditions.equals;
  }
  return data;
}

function handleOperatorFiltering(newKeys, operator, filterState, key) {
  let filterParams = [];
  if (filterState[key]?.filterType === 'date') {
    const selectedDate1 = filterState[key]?.condition1?.dateFrom?.split(' ')[0];
    const selectedDate2 = filterState[key]?.condition2?.dateFrom?.split(' ')[0];
    filterParams = [
      {
        type: filterConditions[filterState[key]?.condition1?.type]
          ? filterConditions[filterState[key]?.condition1?.type]
          : filterState[key]?.condition1?.type,
        value:
          selectedDate1 !== undefined
            ? dayjs(selectedDate1).utc().format(dateTimeFormat)
            : dayjs(filterState[key]?.condition1?.filter)
                .utc()
                .format(dateTimeFormat),
        // for dateForm
        nextDate: null,
      },
      {
        type: filterConditions[filterState[key]?.condition2?.type]
          ? filterConditions[filterState[key]?.condition2?.type]
          : filterState[key]?.condition2?.type,
        value:
          selectedDate2 !== undefined
            ? dayjs(selectedDate2).utc().format(dateTimeFormat)
            : dayjs(filterState[key]?.condition2?.filter)
                .utc()
                .format(dateTimeFormat),
        // for dateForm
        nextDate: null,
      },
    ];
    filterParams = generateOperationDateFilterParams(
      filterParams,
      selectedDate1,
      selectedDate2,
    );
  } else {
    filterParams = [
      {
        type: filterConditions[filterState[key]?.condition1?.type]
          ? filterConditions[filterState[key]?.condition1?.type]
          : filterState[key]?.condition1?.type,
        value: filterState[key]?.condition1?.filter,
      },
      {
        type: filterConditions[filterState[key]?.condition2?.type]
          ? filterConditions[filterState[key]?.condition2?.type]
          : filterState[key]?.condition2?.type,
        value: filterState[key]?.condition2?.filter,
      },
    ];
  }
  return generalCustomFilterGenerator(newKeys, filterParams, operator);
}

function handleNonOperatorFiltering(newKeys, filterState, key) {
  const selectedDate = filterState[key]?.dateFrom?.split(' ')[0];
  const data = {
    type: filterConditions[filterState[key]?.type]
      ? filterConditions[filterState[key]?.type]
      : filterState[key]?.type,
    value: selectedDate !== undefined ? selectedDate : filterState[key]?.filter,
    // for dateForm
    nextDate: null,
  };
  if (data.type === filterConditions.equals && selectedDate) {
    // if condition is "equals", then setup the nextDate
    data.value = dayjs(selectedDate).utc().format(dateTimeFormat);
    data.nextDate = plusOneDayUTC(selectedDate);
  }
  if (data.type === filterConditions.lessThan && selectedDate) {
    // if condition is "lte", then add one day
    data.value = plusOneDayUTC(selectedDate);
  }
  if (data.type === filterConditions.notBlank) {
    data.value = null;
    data.type = filterConditions.notEqual;
  }
  if (data.type === filterConditions.blank) {
    data.value = null;
    data.type = filterConditions.equals;
  }
  return generalCustomFilterGenerator(newKeys, data);
}

export function generalFilter(filterState) {
  let internalFilters = {};

  Object.keys(filterState).forEach(key => {
    const operator = filterState[key]?.operator?.toLowerCase();
    const newKeys = key.split('.');

    if (operator) {
      const customFilter = handleOperatorFiltering(
        newKeys,
        operator,
        filterState,
        key,
      );
      internalFilters = {
        ...internalFilters,
        ...customFilter,
      };
    } else {
      const customFilter = handleNonOperatorFiltering(
        newKeys,
        filterState,
        key,
      );

      internalFilters = {
        ...internalFilters,
        ...customFilter,
      };
    }
  });
  return internalFilters;
}

export function selectorFilter(filterState) {
  let internalFilters = {};
  const multipleFilters = [];
  const keys = Object.keys(filterState);

  function valueTypeCheckingFunc(key, value) {
    const valueTypeColumnList = {
      isChargeable: 'boolean',
      rateOverride: 'boolean',
      noDispatch: 'boolean',
      hasDgs: 'boolean',
      applyFAF: 'boolean',
      equipmentType: 'number',
      goodsType: 'number',
      bookingFlag: 'number',
      invoiceStatus: 'number',
      stackRun: 'boolean',
      isStackRun: 'boolean',
    };

    if (valueTypeColumnList[key] === 'boolean') {
      return value === 'true';
    }
    if (valueTypeColumnList[key] === 'number') {
      return Number(value);
    }
    return value;
  }

  Object.keys(filterState).forEach(key => {
    let mappedKey = key;
    if (Object.keys(StatusColumnMapping).includes(key)) {
      mappedKey = StatusColumnMapping[key];
    }

    const newKeys = mappedKey.split('.');
    const filterValue = filterState[key]?.filter?.split(',');
    const customFilterArray = [];

    const data = {
      type: filterConditions[filterState[key]?.type]
        ? filterConditions[filterState[key]?.type]
        : filterState[key]?.type,
    };

    switch (newKeys.length) {
      case 1:
        filterValue.forEach(filter => {
          customFilterArray.push({
            [mappedKey]: {
              [data.type]: valueTypeCheckingFunc(key, filter),
            },
          });
        });
        internalFilters = {
          ...internalFilters,
          or: customFilterArray,
        };
        if (keys.length >= 2) {
          // has more than 2 of bookingPlace filter
          multipleFilters.push(internalFilters);
        }
        break;
      case 2:
        filterValue.forEach(filter => {
          customFilterArray.push({
            [newKeys[0]]: {
              [newKeys[1]]: {
                [data.type]: valueTypeCheckingFunc(key, filter),
              },
            },
          });
        });
        internalFilters = {
          ...internalFilters,
          or: customFilterArray,
        };
        if (keys.length >= 2) {
          // has more than 2 of bookingPlace filter
          multipleFilters.push(internalFilters);
        }
        break;
      case 3:
        filterValue.forEach(filter => {
          customFilterArray.push({
            [newKeys[0]]: {
              [newKeys[1]]: {
                [newKeys[2]]: {
                  [data.type]: valueTypeCheckingFunc(key, filter),
                },
              },
            },
          });
        });
        internalFilters = {
          ...internalFilters,
          or: customFilterArray,
        };
        if (keys.length >= 2) {
          // has more than 2 of bookingPlace filter
          multipleFilters.push(internalFilters);
        }
        break;
    }
  });
  if (multipleFilters.length > 0) {
    // has more than 2 of bookingPlace filter
    internalFilters = { and: multipleFilters };
  }

  return internalFilters;
}
