import {
  faArrowsToDot,
  faBan,
  faBox,
  faBoxesPacking,
  faBoxOpen,
  faCheckCircle,
  faHandPeace,
  faTruckFast,
  faTruckRampBox,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';
import { Steps } from 'antd';
import { BookingStatusTypes } from 'components/config/types/booking';
import { serverTimeToLocalTime } from 'helpers/date';
import React from 'react';
import tw, { styled } from 'twin.macro';

const { Step } = Steps;

const StyledFormGroup = styled.div`
  ${tw`relative w-full p-2`}
`;

export interface Props extends ICellRendererParams {
  style?: {};
  field?: string;
}

export default function BookingMovesHistory(props: Props) {
  const { data } = props;
  const allMoves = data?.bookingHistories?.nodes;
  const moves = [];
  let moveTemp = [];
  allMoves?.forEach(el => {
    moveTemp.push(el);
    if (el?.bookingLegEvent?.id === BookingStatusTypes.UNALL) {
      moves.push(moveTemp);
      moveTemp = [];
    }
  });
  moves.push(moveTemp);

  const renderStatusIcon = statusCode => {
    switch (statusCode) {
      case 'ALL':
        return faArrowsToDot;
      case 'ACC':
        return faCheckCircle;
      case 'PUP':
        return faBoxesPacking;
      case 'PAK':
        return faBox;
      case 'UPK':
        return faBoxOpen;
      case 'OFL':
        return faTruckRampBox;
      case 'DEL':
        return faHandPeace;
      case 'CANC':
        return faBan;
      default:
        return faTruckFast;
    }
  };

  const renderMoves = (moves, index) => {
    return (
      <Steps key={index} current={4} labelPlacement="vertical">
        {moves?.map(e => {
          const { createdByUser } = e;
          const firstName = createdByUser?.contact?.firstName || '';
          const lastName = createdByUser?.contact?.lastName || '';
          const createdBy = `${firstName} ${lastName}`.trim();
          return (
            <Step
              key={e?.id}
              icon={
                <FontAwesomeIcon
                  icon={renderStatusIcon(e?.bookingLegEvent?.code)}
                  style={{
                    fontSize: '22px',
                    color: 'inherit',
                    marginRight: '8px',
                  }}
                />
              }
              title={e?.bookingLegEvent?.code}
              description={
                <div>
                  <span>{e?.bookingLegEvent?.description}</span>
                  <p>
                    {e?.createdOn
                      ? serverTimeToLocalTime(e?.createdOn, 'MM-dd-yyyy HH:mm')
                      : ''}
                  </p>
                  <p>{`by ${createdBy || createdByUser?.userName}`}</p>
                  {e?.bookingLegEvent?.code === 'ALL'
                    ? e?.asset?.registration || data?.asset?.registration
                    : null}
                </div>
              }
            />
          );
        })}
      </Steps>
    );
  };

  return (
    <StyledFormGroup id={props.field} style={props.style}>
      {moves.map((move, index) => renderMoves(move, index))}
    </StyledFormGroup>
  );
}
