import { GridOptions } from 'ag-grid-community';
import BookingMoveHistory from 'components/ReactGrid/renderers/BookingMoveHistory';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';

export const legGridConfig = {
  headerHeight: 24,
  rowHeight: 24,
  masterDetail: true,
  detailRowAutoHeight: true,
  detailCellRenderer: 'BookingMoveHistory',
  detailCellRendererParams: {
    getDetailRowData: params => {
      return params?.successCallback(params.bookingHistories);
    },
  },
  components: {
    CustomCell,
    BookingMoveHistory,
  },

  maintainColumnOrder: true,
  suppressContextMenu: true,
  enablePivot: true,
  defaultColDef: {
    flex: 1,
    resizable: true,
    sortable: true,
    suppressMenu: true,
    cellRenderer: 'CustomCell',
  },
  columnDefs: [
    {
      headerName: 'Leg No',
      field: 'legNo',
      colId: 'legNo',
      cellRenderer: 'agGroupCellRenderer',
      width: 100,
      pinned: 'left',
      tooltipValueGetter: () => {
        return 'Double click to expand cell ';
      },
    },
    {
      headerName: 'From',
      colId: 'bookingPlaceFrom.organizationLocation.placeName',
      field: 'bookingPlaceFrom.organizationLocation.placeName',
      width: 200,
    },
    {
      headerName: 'To',
      colId: 'bookingPlaceTo.organizationLocation.placeName',
      field: 'bookingPlaceTo.organizationLocation.placeName',
      width: 200,
    },
    {
      headerName: 'Last Status',
      colId: 'lastStatus',
      field: 'lastStatus',
      valueGetter: params => {
        // const lastStatus = params
        const bookingHistories = params?.data?.bookingHistories?.nodes;
        if (bookingHistories?.length) {
          return bookingHistories[bookingHistories?.length - 1]?.bookingLegEvent
            ?.code;
        }
        return null;
      },
      width: 200,
    },
    {
      headerName: 'Pickup Ref.',
      colId: 'pickupRef',
      field: 'pickupRef',
      width: 200,
    },
    {
      headerName: 'Delivery Ref.',
      colId: 'deliveryRef',
      field: 'deliveryRef',
      width: 200,
    },

    {
      headerName: 'Activity Note',
      colId: 'activityNote',
      field: 'activityNote',
      width: 200,
    },
    {
      headerName: 'Delivery Note',
      colId: 'deliveryNote',
      field: 'deliveryNote',
      width: 200,
    },
  ],
} as GridOptions;
